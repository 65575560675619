import React from 'react';
import InputField from '../../common/InputField/InputField';
import { Field, Form } from 'formik';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  nickname: Yup.string().required('Required'),
  coupon: Yup.string().required('Required'),
});

const UserForm = () => {
  return (
    <Form>
      <Field name="nickname" component={InputField} label="Nickname" />
      <Field name="coupon" component={InputField} label="Coupon" />
    </Form>
  );
};

export default UserForm;
