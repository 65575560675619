import React, { FC, useState } from 'react';
import Modal from 'react-modal';
import 'simplebar/dist/simplebar.min.css';

import './ItemModal.scss';
import ModalFooter from './ModalFooter/ModalFooter';
import ModalHeader from './ModalHeader/ModalHeader';
import Stepper from '../common/Stepper/Stepper';
import { IStep } from '../../types/Step.interface';
import usePackage from './usePackage';
import { MODAL_TIMEOUT } from './constants';
import { getSubmitButtonText } from './helpers';
import Payways from '../steps/Payways/Payways';
import ItemDescription from '../steps/ItemDescription/ItemDescription';
import UserForm from '../steps/UserForm/UserForm';
import { Formik } from 'formik';
import stepsValidationSchema from './stepsValidationSchema';

interface Props {
  id: string | null;
  closeModal: () => void;
}

const ItemModal: FC<Props> = ({id, closeModal}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [{name, description, price}, error] = usePackage(id);

  const onSubmit = () => {
    setCurrentStep((prevCurrentStep) => {
      if (prevCurrentStep !== steps.length) {
        return prevCurrentStep + 1;
      } else {
        window.open('https://google.com');
      }

      return prevCurrentStep;
    });
  };

  const handleModalClose = () => {
    setCurrentStep(1);
  };

  const steps: IStep[] = [
    <ItemDescription description={description}/>,
    <UserForm/>,
    <Payways/>,
  ].map((component, index) => ({sequence: index + 1, component}));

  const submitButtonText = getSubmitButtonText(currentStep, steps.length, price);

  return (
    <Modal
      isOpen={!!id}
      onRequestClose={closeModal}
      onAfterClose={handleModalClose}
      contentLabel="Example Modal"
      className="modal-custom"
      overlayClassName="modal__overlay--dark"
      closeTimeoutMS={MODAL_TIMEOUT}
      ariaHideApp={false}
    >
      <ModalHeader title={name} closeModal={closeModal}/>
      <Formik
        initialValues={{nickname: '', coupon: '', payway: ''}}
        validationSchema={stepsValidationSchema[currentStep]}
        onSubmit={onSubmit}
      >
        <>
          {error || (
            <div className="modal__body">
              <Stepper activeStep={currentStep} steps={steps}/>
            </div>
          )}
          <ModalFooter buttonText={submitButtonText} hidden={!id || !!error}/>
        </>
      </Formik>
    </Modal>
  );
};

export default ItemModal;
