import React, { FC, InputHTMLAttributes } from 'react';
import './InputField.scss'
import { FieldInputProps } from 'formik';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  field: FieldInputProps<string>
}

const InputField: FC<Props> = ({label, field, ...inputProps}) => {
  return (
    <div className="field">
      <label>{ label }</label>
      <input {...field} { ...inputProps } />
    </div>
  );
};

export default InputField;
