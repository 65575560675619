import {useEffect, useState} from 'react';
import api from '../services/api';
import {IPayway} from '../types/Payway.interface';

const usePayways = (): IPayway[] => {
  const [payways, setPayways] = useState<IPayway[]>([]);

  useEffect(() => {
    const fetchPayways = async () => {
      try {
        const fetchedPayways = await api.get<IPayway[]>('payways');

        setPayways(fetchedPayways);
      } catch {
        console.error('Payways were not fetched')
      }
    }

    fetchPayways();
  }, []);

  return payways;
};

export default usePayways;
