export const getSubmitButtonText = (currentStep: number, lastStep: number, ...params: string[]): string => {
  switch (currentStep) {
    case 1:
      return `Купить за ${params[0]} RUB`;
    case lastStep:
      return `Перейти к оплате`;
    default:
      return `Далее`;
  }
}
