import React, { FC } from 'react';
import './ModalHeader.scss'
import CloseIcon from '../../../assets/close.icon';

interface Props {
  title: string;
  closeModal: () => void
}

const ModalHeader: FC<Props> = ({title, closeModal}) => {
  return (
    <header className="modal__header">
      <h2>{title}</h2>
      <button className="modal__close-button" onClick={closeModal}>
        <CloseIcon />
      </button>
    </header>
  );
};

export default ModalHeader;
