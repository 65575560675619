import { validationSchema as step2ValidationSchema} from '../steps/UserForm/UserForm';
import { validationSchema as step3ValidationSchema } from '../steps/Payways/Payways';
import { OptionalObjectSchema } from 'yup/lib/object';

const stepsValidationSchema: { [key: number]: OptionalObjectSchema<any> | null } = {
  1: null,
  2: step2ValidationSchema,
  3: step3ValidationSchema
}

export default stepsValidationSchema
