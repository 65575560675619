import React, { FC } from 'react';
import './ModalFooter.scss'
import { useFormikContext } from 'formik';

interface Props {
  hidden?: boolean;
  buttonText: string,
}
const ModalFooter: FC<Props> = ({ hidden = false, buttonText }) => {
  const { submitForm, isValid  } = useFormikContext();

  if (hidden) {
    return null;
  }

  return (
    <footer className="modal__footer">
      <button disabled={!isValid} onClick={submitForm}>
        {buttonText}
      </button>
    </footer>
  );
};

export default ModalFooter;
