import React, { FC } from 'react';
import { useField } from 'formik';

import './CardField.scss';
import classNames from 'classnames/bind';

interface ICard {
  name: string;
  image: string;
  label: string;
  id: number;
}

interface Props {
  card: ICard;
  name: string;
  value: string;
}

const CardField: FC<Props> = ({card: {image, label, name}, ...inputProps}) => {

  const [field, , {setValue}] = useField({...inputProps, type: 'radio'});

  const handleCardClick = () => {
    setValue(inputProps.value)
  }

  const className = classNames(
    'card-field',
    {
      'card-field--active': field.checked,
    }
  );

  return (
    <div className={className} onClick={handleCardClick}>
      <img src={image} alt={name}/>
      <div className="card-field__title">
        <span>{label}</span>
      </div>

      <input type="radio" {...field} />
    </div>
  );
};

export default CardField;
