import React, { FC } from 'react';
import './Step.scss';

const Step: FC = ({children}) => {
  return (
    <div className="modal__step stepper__step">
      {children}
    </div>
  );
};

export default Step;
