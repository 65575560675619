const BASE_URL = process.env.API_URL || 'https://srv1.leonardjke.net/api/v1';

interface IResponse<T> {
  data: T;
}

class Api {
  constructor(private url: string) {}

  private async fetch<T>(path: string, config?: RequestInit): Promise<IResponse<T>> {
    const itemResponse = await fetch(`${this.url}/${path}`, config);

    return itemResponse.json();
  }

  public async get<T>(path: string): Promise<T> {
    const item = await this.fetch<T>(path);

    return item.data;
  }
}

export default new Api(BASE_URL);