import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const DEFAULT_ITEM_CLASS = 'item';

class ItemModal extends HTMLElement {
  connectedCallback() {
    const mountPoint = document.createElement('dialog');

    this.attachShadow({ mode: 'open' }).appendChild(mountPoint);

    const itemClass = this.getAttribute('itemClass') || DEFAULT_ITEM_CLASS;

    ReactDOM.render(<App itemClass={itemClass} />, mountPoint);
  }
}

customElements.define('item-modal', ItemModal);
