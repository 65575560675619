import React, { FC } from 'react';
interface Props {
  width?: number;
  height?: number;
}
const CloseIcon: FC<Props>= ({ width=40, height=40}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         className="icon"
         width={width}
         height={height}
         viewBox={`0 0 24 24`}
         strokeWidth="2"
         stroke="currentColor"
         fill="none"
         strokeLinecap="round"
         strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <line x1="18" y1="6" x2="6" y2="18"/>
      <line x1="6" y1="6" x2="18" y2="18"/>
    </svg>
  );
};

export default CloseIcon;
