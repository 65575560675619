import { createContext } from 'react';
import {IPayway} from '../types/Payway.interface';

interface IStore {
  payways: IPayway[];
}

const StoreContext = createContext<IStore>({
  payways: []
});

export default StoreContext;

