import {useEffect, useState} from 'react';
import {MODAL_TIMEOUT} from './constants';
import api from '../../services/api';

interface IPackage {
  name: string;
  description: string;
  price: string;
}

const usePackage = (id: string | null): [item: IPackage, error: string] => {
  const [error, setError] = useState('');
  const [item, setItem] = useState<IPackage>({} as IPackage);

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const item = await api.get<IPackage>(`packages/${id}`);

        setItem(item);
        setError('');
      } catch {
        setError('Something went wrong');
      }
    };

    if (id) {
      fetchPackage();
    } else {
      setTimeout(() => setItem({ } as IPackage), MODAL_TIMEOUT);
    }
  }, [ id ]);

  return [ item, error ];
}

export default usePackage;
