import React, {FC, useEffect, useState} from 'react';

import ItemModal from './components/ItemModal/ItemModal';
import usePayways from './hooks/usePayways';
import StoreContext from './contexts/StoreContext';

interface Props {
  itemClass: string
}

const App: FC<Props> = ({ itemClass}) => {
  const [itemId, setItemId] = useState<string | null>(null);

  const payways = usePayways();

  useEffect(() => {
    const items = document.querySelectorAll<HTMLElement>(`[data-package-id]`);

    const openModal = (event: Event) => {
      const id = (event?.currentTarget as HTMLElement)?.dataset.packageId;

      if (id) {
        setItemId(id);
      }
    }

    items.forEach((item) => {
      item.addEventListener('click', openModal, true);
      item.style.cursor = 'pointer';
    });
  }, [itemClass]);

  const closeModal = () => {
    setItemId(null);
  };

  return (
    <StoreContext.Provider value={{ payways }}>
      <ItemModal id={itemId} closeModal={closeModal} />
    </StoreContext.Provider>
  );
}

export default App;
