import React, { FC } from 'react';
import SimpleBar from 'simplebar-react';

interface Props {
  description: string;
}

const ItemDescription: FC<Props> = ({ description }) => {
  return (
    <SimpleBar style={{ width: '100%' }}>
      <article dangerouslySetInnerHTML={{ __html: description }}/>
    </SimpleBar>
  );
};

export default ItemDescription;
