import React, { useContext } from 'react';

import StoreContext from '../../../contexts/StoreContext';
import * as Yup from 'yup';
import { Form } from 'formik';
import CardField from '../../common/CardField/CardField';
import './Payways.scss';

export const validationSchema = Yup.object({
  payway: Yup.string().required('Required'),
});

const Payways = () => {
  const { payways } = useContext(StoreContext);

  return (
    <Form>
      <div className="payways-form">
        {
          payways.map((card) => (<CardField key={card.id} card={card} name="payway" value={card.name} />))
        }
      </div>
    </Form>
  );
};

export default Payways;
