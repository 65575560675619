import React, { FC, useEffect } from 'react';
import './Stepper.scss';
import Step from './Step/Step';
import { IStep } from '../../../types/Step.interface';
import { useFormikContext } from 'formik';

interface Props {
  activeStep?: number,
  steps: IStep[],
}

const Stepper: FC<Props> = ({ activeStep = 1, steps}) => {
  const { validateForm } = useFormikContext();

  useEffect(() => {
    validateForm();
  }, [activeStep, validateForm]);

  return (
    <div className="stepper">
      <div className="stepper__wrapper" style={{transform: `translateX(${(activeStep - 1) * -100}%)`}}>
        {steps.map(({ sequence, component }) => (
          <Step key={sequence}>
            {component}
          </Step>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
